import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  LoginType,
  LoginResponseType,
  AuthUserResponseType,
  ChangePasswordResult,
  ChangePasswordRequest,
  ResetPasswordResult,
  ResetPasswordRequest,
  CheckVerificationResult,
  VerifyAccountResult,
  CheckVerificationRequest,
  VerifyAccountRequest,
  UpdateResetPasswordResult,
  UpdateResetPasswordRequest,
  ResendVerificationResult,
  ResendVerificationRequest,
} from './types';
import { refreshUserState, setCurrentUser } from './currentUser.slice';
import { API } from '../../../_shared/utils/_urls';
import { store } from '../../store';

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/`,
    prepareHeaders: headers => {
      headers.set('x-api-key', API_KEY);
      headers.set('app', 'ADMIN');
      if (store.getState().currentUserState.token) {
        headers.set('Authorization', store.getState().currentUserState.token!);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    loginUser: builder.mutation<LoginResponseType, LoginType>({
      query(data) {
        return {
          url: 'auth/login',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            setCurrentUser({
              currentUser: data.data.user,
              token: data.data.token,
            })
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getAuthUser: builder.mutation<AuthUserResponseType, {}>({
      query() {
        return {
          url: `${API.USERS}/me`,
          method: 'GET',
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(refreshUserState({ currentUser: data.data.user }));
        } catch (error) {
          console.log(error);
        }
      },
      transformErrorResponse: (result: any) => {
        return result.data;
      },
    }),
    changePassword: builder.mutation<
      ChangePasswordResult,
      ChangePasswordRequest
    >({
      query(data) {
        return {
          url: `${API.CHANGE_PASSWORD}`,
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (result: any): ChangePasswordResult => {
        return result.data;
      },
    }),
    resetPassword: builder.mutation<ResetPasswordResult, ResetPasswordRequest>({
      query(data) {
        return {
          url: `${API.RESET_PASSWORD}`,
          method: 'POST',
          body: data,
        };
      },
      transformResponse: (result: any): ResetPasswordResult => {
        return result.data;
      },
    }),
    updateResetPassword: builder.mutation<
      UpdateResetPasswordResult,
      UpdateResetPasswordRequest
    >({
      query(data) {
        return {
          url: `${API.UPDATE_PASSWORD}`,
          method: 'POST',
          body: data,
        };
      },
    }),
    checkVerification: builder.mutation<
      CheckVerificationResult,
      CheckVerificationRequest
    >({
      query(data) {
        return {
          url: `${API.CHECK_VERIFICATION}`,
          method: 'POST',
          body: data,
        };
      },
    }),
    verifyAcount: builder.mutation<VerifyAccountResult, VerifyAccountRequest>({
      query(data) {
        return {
          url: `${API.VERIFY_ACCOUNT}`,
          method: 'POST',
          body: data,
        };
      },
    }),
    resendVerification: builder.mutation<
      ResendVerificationResult,
      ResendVerificationRequest
    >({
      query(data) {
        console.log('email', data);
        return {
          url: `${API.RESEND_VERIFICATION}`,
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useGetAuthUserMutation,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useCheckVerificationMutation,
  useVerifyAcountMutation,
  useUpdateResetPasswordMutation,
  useResendVerificationMutation,
} = authApi;
